<template>
  <my-modal
    v-show="props.show"
    @close="emit('not-show')"
  >
    <template #header>
      <div class="violations-setting-block">
        <div
          class="btn-primary config-btn-primary violations-setting-button"
          style="height: 100%"
          @mouseenter="toggleShowSettings"
          @mouseleave="toggleShowSettings"
        >
          {{ violationsCount.summ }}
          нарушений. Меню для карты:
        </div>
        <div
          v-show="isShowSettings"
          class="violations-setting-list"
          @mouseenter="toggleShowSettings"
          @mouseleave="toggleShowSettings"
        >
          <div class="text-height-0">
            <div class="w-100 text-center font-weight-bold text-warning">
              !!! Влияет на быстродействие карты !!!
            </div>
            <button
              type="button"
              class="btn btn-link py-0 violations-setting-list-checked-all"
              @click="checkAllViolations"
            >
              выбрать все
            </button>
            <button
              type="button"
              class="btn btn-link py-0 violations-setting-list-not-checked-all"
              @click="clearAllViolations"
            >
              снять все
            </button>
          </div>

          <table>
            <tbody>
              <template
                v-for="(violation, index) in violationsDesc"
                :key="index"
              >
                <tr :class="boldClass(violation.id)">
                  <td>
                    <input
                      class="violations-setting-list-count"
                      type="checkbox"
                      :id="'map-modal-' + violation.id"
                      :value="violation.id"
                      v-model="checkedViolationsIds"
                    />
                  </td>
                  <td class="text-right">
                    <label
                      class="violations-setting-list-count"
                      :for="'map-modal-' + violation.id"
                      style="width: 100%"
                    >
                      {{ violationsCount[violation.id] || 0 }}
                    </label>
                  </td>
                  <td>
                    <label
                      :for="'map-modal-' + violation.id"
                      class="violations-setting-list-label"
                    >
                      {{ violation.description }}
                    </label>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <div
        v-html="trackInfoHtml"
        id="map-modal-text"
      ></div>
      <ui-close-button @click="emit('not-show')" />
    </template>

    <template #body>
      <div
        v-show="!isReadyTrack"
        style="margin-top: 50%; transform: translate(0, -200%)"
      >
        <preloader />
      </div>
      <div
        id="ratio-out-line-map"
        style="width: 100%; height: 99%"
        :style="{ opacity: isReadyTrack ? '1' : '0' }"
      ></div>
    </template>
  </my-modal>
</template>

<script setup>
import { inject, onMounted, ref, watch } from 'vue';

import MyModal from '@/App/Components/MyModal.vue';
import Preloader from './Preloader.vue';
import UiCloseButton from './Buttons/UiCloseButton.vue';

import { mapLeafletMain } from '../../Leaflet/map_leaflet_main.js';
import { callOurPositions } from '../use/getElementPositionForMapModal';
import { violationsDescriptions } from '../../src/dataRequest/mainScript';

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  isRoughly: {
    type: Boolean,
    required: true,
    default: false,
  },
  id: {
    type: String,
    required: true,
  },
  objName: {
    type: String,
    required: true,
  },
  violations: {
    type: Array,
    required: false,
  },
  interval: {
    type: Array,
    required: false,
  },
});

const emit = defineEmits(['close', 'not-show', 'reinit']);

const isShowSettings = ref(false);
const violationsCount = ref({ summ: 0 });
const violationsDesc = ref([]);
const checkedViolationsIds = ref([]);
const pointsList = ref({});
const isReadyTrack = ref(false);
const trackInfoHtml = ref('');
const oldData = ref({ id: '', objName: '', interval: [], violations: [] });

const globalObjectsList = inject('globalObjectsList');

onMounted(() => {
  mapLeafletMain.map_leaflet_initialization();

  oldData.value.id = props.id;
  oldData.value.objName = props.objName;
  oldData.value.interval = props.interval;
  oldData.value.violations = props.violations;

  const timeInterval = {
    timeBegin: props.interval[0], // timeBegin: 1658123033,
    timeEnd: props.interval[1], // timeEnd: 1658126633,
  };
  const button = document.querySelector('.violations-setting-button');

  if (props.isRoughly) {
    violationsDesc.value = props.violations;
  } else {
    violationsDesc.value = violationsDescriptions._desc;
  }

  callOurPositions(
    globalObjectsList[props.id] || { id: props.id },
    button,
    trackInfoHtml,
    timeInterval,
    drawHeadHandler,
  );
});

watch(
  () => props.id,
  (id) => {
    if (id !== oldData.value.id) emit('reinit');
    if (trackInfoHtml.value.indexOf('Ошибка') >= 0) emit('reinit');
  },
);
watch(
  () => props.objName,
  (name) => {
    if (name !== oldData.value.objName) emit('reinit');
    if (trackInfoHtml.value.indexOf('Ошибка') >= 0) emit('reinit');
  },
);
watch(
  () => props.interval,
  (interval) => {
    if (interval.toString() !== oldData.value.interval.toString())
      emit('reinit');
    if (trackInfoHtml.value.indexOf('Ошибка') >= 0) emit('reinit');
  },
);
watch(
  () => props.violations,
  (violations) => {
    if (JSON.stringify(violations) !== JSON.stringify(oldData.value.violations))
      emit('reinit');
    if (trackInfoHtml.value.indexOf('Ошибка') >= 0) emit('reinit');
  },
);

watch(checkedViolationsIds, (ids) => {
  toggleShowViolationsOnMap(props.id, ids);
});

const checkAllViolations = () => {
  checkedViolationsIds.value = violationsDesc.value.map((obj) => obj.id);
};
const clearAllViolations = () => {
  checkedViolationsIds.value = [];
};

const drawHeadHandler = ({
  points,
  trackInfo,
  infoForShow,
  getBegin,
  getEnd,
  trackBegin,
  trackEnd,
  objName,
}) => {
  violationsCount.value = points.violations_stat;
  violationsCount.value.summ = violationsDesc.value.reduce((acc, obj) => {
    acc += points.violations_stat[obj.id] || 0;
    return acc;
  }, 0);
  pointsList.value = points;
  trackInfoHtml.value = trackInfo;
  isReadyTrack.value = true;

  if (props.isRoughly) checkAllViolations();
};
const boldClass = (id) => {
  return violationsCount.value[id]
    ? 'font-weight-bold violations-setting-list-tr'
    : 'violations-setting-list-tr';
};

const toggleShowViolationsOnMap = async (objId, violationsIds) => {
  const violationsSettingsList = violationsIds.reduce((acc, id) => {
    acc[id] = true;
    return acc;
  }, {});

  mapLeafletMain.addViolationsOnMap(
    pointsList.value,
    objId,
    pointsList.value['objName'],
    pointsList.value['gearboxName'],
    violationsSettingsList,
    true,
  );
};

const toggleShowSettings = () => {
  isShowSettings.value = !isShowSettings.value;
};
</script>

<style lang="scss" scoped>
.violations-setting-block {
  width: 100%;
  position: relative;
  max-width: 350px;
  max-height: 35px;
  font-size: 16px;

  transition: all 0.3s;

  & .violations-setting-button {
    padding: 5px 50px;
  }

  & .violations-setting-list {
    background: rgba(#fff, 0.6);
    height: auto;
    max-height: 60vh;
    opacity: 0.9;
    overflow: scroll;
    z-index: 1003;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0;
    line-height: 1.2;
  }
}
</style>
