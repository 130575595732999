require('regenerator-runtime/runtime');
const url_get_violations_descriptions = `${
  process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
}Api/getViolationsDescriptions`;

import { accessToken } from '../jwtSender';

export class ViolationsDescriptions {
  _desc;
  _spnErrors;

  // requestDescDone = (response) => {
  //     this._desc = this.getViolationsDescriptions_get(response);
  // }

  constructor(fetchJson) {
    this.fetchJson = fetchJson;
    this.laod();
  }

  async laod() {
    try {
      const data = await this.fetchJson(url_get_violations_descriptions, {
        credentials: 'include',
      });
      if (data) {
        this._desc = data.desc;
        this._spnErrors = data.spnErrors;
      }
    } catch (e) {
      console.error(
        'ViolationsDescriptions.laod(fetchJson) error: ',
        e.message,
      );
      infoShowText_helper('Ошибка получения конфигурации нарушений.');
    }
  }

  get desc() {
    if (!this._desc) {
      // this._desc = this.getViolationsDescriptions();
      this.laod();
    }

    return this._desc;
  }

  get spnErrors() {
    if (!this._spnErrors) {
      this.laod();
    }
    return this._spnErrors;
  }
}
