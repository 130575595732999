// require('core-js/modules/es.promise');
// require('core-js/modules/es.object.assign');
// require('core-js/modules/es.object.keys');
require('regenerator-runtime/runtime');
// helpers
require('../../helpers/html_to_excel_helper.js');
require('../../helpers/html_to_xml_helper.js');

import fetchJson from './fetch-json';

// управление картой
import { leafletMain } from '../../Leaflet/leaflet_main';
import mobileEvents from './mobileEvents.js';

export { leafletMain, mobileEvents, globalObjects };

// import {MobileEvents} from './mobileEvents.js';
// import {lodashRenderHtml_helper} from '../../helpers/main_helper.js';
import { getParamsCodifier } from './objectParamsСodifier.js';
// import { OurAggregated } from './ourAggregated.js';
import { ViolationsDescriptions } from './violationsDescriptions';

// import { shiftWork_template } from '../../Template/shiftWork_template/shiftWork_template.js';
// import { skillsManTemplate } from '../../Template/skills_man_template/skills_man_template.js';
// import { SkillsManDetailTemplate } from '../../Template/skills_man_template/skills_man_detail_template.js';
// import { CanIndicatorsTemplate } from '../../Template/skills_man_template/can_indicators_template.js';
// import { ClutchWorkTemplate } from '../../Template/skills_man_template/clutch_work_template.js';
// import { CriticalViolationsTemplate } from '../../Template/skills_man_template/critical_violations_template.js';
// // import {ClientsMonthlyTemplate} from '../../Template/clients_monthly_template/clients_monthly_template.js';
// import { FuelInOutTemplate } from '../../Template/fuel_in_out_template/fuel_in_out_template.js';
// import {RegularMovingByGeoTemplate} from '../../Template/geofencies_template/RegularMovingByGeoTemplate.js';
import { TableRowsDrag } from '../../helpers/tableRowsDrag.js';

import { violationsDescriptionListHtml } from '../../App/Monitoring/Map/templates/violationsDescriptionListHtml';
import { ref } from 'vue';
import {
  addFormatAndValAttributesFromLodash_helper,
  getColNumberAsExcelText_helper,
  getInnerTextFromFormatExcel_helper,
  html_to_excel_helper,
  valueToDisplayFormat_helper,
  addFormatAndValAttributesFromLodash_getDataValue_helper,
} from '../../helpers/html_to_excel_helper.js';

window.html_to_excel_helper = html_to_excel_helper;
window.getColNumberAsExcelText_helper = getColNumberAsExcelText_helper;
window.getInnerTextFromFormatExcel_helper = getInnerTextFromFormatExcel_helper;
window.valueToDisplayFormat_helper = valueToDisplayFormat_helper;
window.addFormatAndValAttributesFromLodash_getDataValue_helper =
  addFormatAndValAttributesFromLodash_getDataValue_helper;
window.addFormatAndValAttributesFromLodash_helper =
  addFormatAndValAttributesFromLodash_helper;

const globalObjects = {
  globalPositions: {},
  positionsFromTemplateDetail: {},
  violationsSettingsList: {},
  clients: [],
};

// const clientsMonthlyTemplate = new ClientsMonthlyTemplate({
//   clients: globalObjects.clients,
//   skillsManTemplate,
//   areaId: 'section-reports'
// });

let isMouseDown = false;
// let refreshObjectsTimer;
// let prevRefreshObjectsEnded = true;
// let objTable_global = '';
function trackDeleterSetText() {}

let objectsTable_global = {
  table: '',
  container: '',

  initialization: function () {
    // this.table = $('#objectsListId');
    this.table = document.getElementsByClassName('objectsListId')[0];
    // this.container = $('#objectsListContainer');
    this.container = document.getElementsByClassName('objectsListContainer')[0];

    this.setClients();

    trackDeleterSetText = (() => {
      const trackDeleter = document.getElementById('track-delete-confirm');
      const textElement = trackDeleter.querySelector('[data-element="text"]');
      return (text) => {
        textElement.innerText = text;
      };
    })();

    // this.tableBuilder();
    this.addDragAndDrop();
  },

  setClients() {
    const { clients } = globalObjects;
    const objClients = this.table.querySelectorAll('.obj-client-search');
    const clientsDictionary = {};

    objClients.forEach((objClient) => {
      const client = objClient.textContent;
      const cIndex = (clientsDictionary[client] ?? 0) - 1;

      if (cIndex > -1) {
        clients[cIndex]['count']++;
      } else {
        clientsDictionary[client] = clients.push({ name: client, count: 1 });
      }
    });

    clients.sort((a, b) => a['name'].localeCompare(b['name']));
  },

  addDragAndDrop: function () {
    // перемещение строк таблицы объектов
    const tableRowsDrag = new TableRowsDrag();
    // tableRowsDrag.init('objectsListId', '.state-number', {
    //   fromSelector: '.target-show-span',
    //   removeClass: 'hover',
    // });
  },
};

const host = document.location.host;
//const url_get_positions = "http://jsproject.local/post_test.php/";
const url_get_positions = `${
  process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
}Api/getPositionsOurPost`;
const url_get_positions_other = `${
  process.env.VUE_APP_BACKEND_URL || window.location.origin + '/'
}Api/getPositionsOtherPost`;
const url_get_positions_py = `//${host}:8082/getpositions`;

// document.addEventListener('DOMContentLoaded', ready);

export const clientSize = ref({ width: 0, height: 0 });
export const violationsDescriptions = new ViolationsDescriptions(fetchJson);
export function ready() {
  document.addEventListener('resize', () => {
    clientSize.value.width = document.documentElement.clientWidth;
    clientSize.value.height = document.documentElement.clientHeight;
  });

  leafletMain.initialization();
  // mobileEvents.fastSwap();

  // datepickerInitialization();
  objectsTable_global.initialization();

  document.body.onmousedown = function () {
    isMouseDown = true;
  };

  document.body.onmouseup = function () {
    isMouseDown = false;
  };

  let positionsList = document.getElementById('positionsList');
  positionsList.addEventListener('change', changepositionsList);

  window.onresize = function () {
    mobileEvents.resizeWindow(); //mobile -> dekstop change version
  };

  const violationsSetting = document.getElementById('violations-setting');
  violationsSetting.addEventListener(
    'touchend',
    mobileEvents.violationsSettingSwitchShow,
  );
  // violationsSetting.addEventListener('click', violationsSettingSwitchShow, false );

  violationsSetting.addEventListener('click', (event) => {
    const element = event.target;

    if (element.classList.contains('violations-setting-list-checked-all')) {
      violationsSettingListCheckedAll(true);
    } else if (
      element.classList.contains('violations-setting-list-not-checked-all')
    ) {
      violationsSettingListCheckedAll(false);
    } else if (
      element.closest('.violations-setting-list-label') ||
      element.closest('.violations-setting-list-count') ||
      element.closest('.violations-setting-list-checkbox')
    ) {
      changeViolationsSetting();
    } else if (
      element.classList.contains(
        'violations-setting-list-show-drains-and-refueling',
      )
    ) {
      const isShow = +element.dataset.isShow;

      changeButtonShowDrainsAndRefuelingOnMap(element, isShow);

      changeShowDrainsAndRefuelingOnMap(isShow);
    }
  });

  const changeButtonShowDrainsAndRefuelingOnMap = (el, isShow) => {
    if (isShow === 0) {
      el.innerHTML = 'показать заправки, сливы';
      el.dataset.isShow = '1';
      return;
    }

    el.innerHTML = 'скрыть заправки, сливы';
    el.dataset.isShow = '0';
  };

  violationsSetting.addEventListener('mouseover', function () {
    let className = 'active';
    let violationsSettingList = document.getElementById(
      'violations-setting-list',
    );
    if (
      violationsSettingList &&
      !violationsSettingList.classList.contains(className)
    ) {
      violationsSettingList.classList.add(className);
    }
  });

  violationsSetting.addEventListener('mouseout', function () {
    let className = 'active';
    let violationsSettingList = document.getElementById(
      'violations-setting-list',
    );
    if (
      violationsSettingList &&
      violationsSettingList.classList.contains(className)
    ) {
      violationsSettingList.classList.remove(className);
    }
  });
  // setTimeout(refreshObjects, 10000);
  // загрузка завершена
  // document.getElementById('loading-content').style.display = 'none';
}
export function objectsListFormSubmit(templateSelected = '', data) {
  // клик по id='get-template-button'
  // какой отчет выбран
  // каждому отчету своя логика
  switch (templateSelected) {
    case 'skillsMan':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'skillsManTemplate', data },
        }),
      );
      break;

    case 'canIndicators':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'canIndicators', data },
        }),
      );
      break;

    case 'clutchWork':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'clutchWork', data },
        }),
      );
      break;

    case 'criticalViolations':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'criticalViolations', data },
        }),
      );
      break;
    case 'rudeViolationsXML':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'rudeViolationsXML', data },
        }),
      );
      break;

    case 'skillsManDetil':
      // запуск отчета реализован в skills_man_detail_template.js через callOurPositions
      // форма настройки отчета в shiftWork_template_setting.php
      // violationsDescriptions // Нужен для формирование списка!!!

      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: {
            name: 'skillsManDetail',
            violationsDescriptions: violationsDescriptions._desc,
            data,
          },
        }),
      );
      break;

    case 'skillsManDetail':
      // запуск отчета реализован в skills_man_detail_template.js через callOurPositions
      // форма настройки отчета в shiftWork_template_setting.php
      // violationsDescriptions // Нужен для формирование списка!!!

      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: {
            name: 'skillsManDetail',
            violationsDescriptions: violationsDescriptions._desc,
            data,
          },
        }),
      );
      break;

    case 'shiftWork':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'shiftWork', data },
        }),
      );
      // запуск отчета реализован в shiftWork_template.js через callOurAggregated
      // форма настройки отчета в shiftWork_template_setting.php
      break;

    case 'clientsMonthly':
      // запуск отчета реализован в shiftWork_template.js через callOurAggregated
      // форма настройки отчета в shiftWork_template_setting.php

      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'clientsMonthlyTemplate', data },
        }),
      );
      break;

    case 'fuelInOut_consumption':
      // // запуск отчета реализован в fuel_in_out_template.js

      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'fuelInOut_consumption', data },
        }),
      );
      break;

    case 'regularMovingByGeo':
      // запуск отчета реализован в fuel_in_out_template.js
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'regularMovingByGeo', data },
        }),
      );
      break;

    case 'overspeed':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'overspeed', data },
        }),
      );
      break;

    case 'geoInOutTemplate':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('geo-in-out-template-show', {
          detail: { name: 'geoInOutTemplate', data },
        }),
      );
      break;

    case 'fuelInOut_cistern':
      // // запуск отчета реализован в fuel_in_out_template.js

      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'fuelInOut_cistern', data },
        }),
      );
      break;

    case 'evacuatorMoving':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'evacuatorMoving', data },
        }),
      );
      break;

    case 'specMachine':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'specMachine', data },
        }),
      );
      break;
    case 'diagnostic':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'diagnostic', data },
        }),
      );
      break;
    case 'diagnosticDetail':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'diagnosticDetail', data },
        }),
      );
      break;
    case 'descriptionDiagnostic':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'descriptionDiagnostic', data },
        }),
      );
      break;
    case 'oilPressure':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'oilPressure', data },
        }),
      );
      break;
    case 'engineOilTemp':
      document.getElementById('app').dispatchEvent(
        new CustomEvent('get-template-button-event', {
          detail: { name: 'engineOilTemp', data },
        }),
      );
      break;

    default:
      alert('Отчет не доступен');
  }
}
function changeViolationsSetting() {
  setTimeout(changeViolationsSettingAction, 0);
}
function changeViolationsSettingAction(checkedId, isChecked) {
  let selectedIndex =
    document.getElementById('positionsList').options.selectedIndex;
  let objId =
    document.getElementById('positionsList').options[selectedIndex].value;

  let violationsSettingCheckboxes = document.querySelectorAll(
    '#violations-setting-list [type="checkbox"]',
  );

  let settingTemp = {};

  for (let i = 0; i < violationsSettingCheckboxes.length; i++) {
    if (violationsSettingCheckboxes[i]['id'] === checkedId && !isChecked)
      continue;
    if (violationsSettingCheckboxes[i]['id'] === checkedId) {
      settingTemp[violationsSettingCheckboxes[i]['id']] = isChecked;
      continue;
    }
    if (violationsSettingCheckboxes[i]['checked']) {
      const dataArray = JSON.parse(violationsSettingCheckboxes[i].getAttribute('data-array'));
      for (const item of dataArray) {
        settingTemp[item] = true;
      }
    }
  }
  globalObjects.violationsSettingsList[objId] = settingTemp;
  leafletMain.addViolationsOnMap(
    globalObjects.globalPositions[objId]['points'],
    objId,
    globalObjects.globalPositions[objId]['points']['objName'],
    globalObjects.globalPositions[objId]['points']['gearboxName'],
    globalObjects.violationsSettingsList[objId],
    true,
  );
}

function changeShowDrainsAndRefuelingOnMap(isShow) {
  const selectedIndex =
    document.getElementById('positionsList').options.selectedIndex;
  const objId =
    document.getElementById('positionsList').options[selectedIndex].value;

  leafletMain.addDrainsAndRefuelingOnMap(
    globalObjects.globalPositions[objId]['points'],
    isShow,
  );
}

function violationsSettingListCheckedAll(isChecked) {
  let violationsSettingCheckboxes = document.querySelectorAll(
    '#violations-setting-list [type="checkbox"]',
  );
  for (let i = 0; i < violationsSettingCheckboxes.length; i++) {
    violationsSettingCheckboxes[i].checked = isChecked;
  }
  changeViolationsSetting();
}

function changepositionsList() {
  let selectedObjId = this.value;
  // alert(selectedObjId);
  leafletMain.mapFitBounds(selectedObjId);
  violationsSettingListInnerHtml(
    violationsDescriptions._desc,
    globalObjects.violationsSettingsList[selectedObjId],
    globalObjects.globalPositions[selectedObjId]['points']['violations_stat'],
  );
  // selection.textContent = "Вы выбрали: " + selectedOption.text;
}

// function addEventClickOnShowOnMaps(showOnMapItem) {
//   showOnMapItem.addEventListener("click", function() {
//     let objData = {};
//     objData.dataId = +this.getAttribute('data-id');
//     let trObject = document.getElementById(objData.dataId);

//     objData.name = trObject.getAttribute('data-name');
//     objData.stateNumber = trObject.getElementsByClassName('state-number')[0].textContent;
//     objData.lat = trObject.getElementsByClassName('lat-on-map')[0].textContent;
//     objData.lon = trObject.getElementsByClassName('lon-on-map')[0].textContent;
//     objData.speed = trObject.getElementsByClassName('speed-on-map')[0].textContent;
//     objData.head = trObject.getElementsByClassName('head-on-map')[0].textContent;
//     objData.lastPosTime = 1000 * trObject.getElementsByClassName('lastPosTime-on-map')[0].textContent;

//     if(leafletMain.leafletAddObjPositions( objData, false )) {
//       // активировать кнопку - объект на карте отображается
//       this.classList.add('active');
//     } else {
//       // снять активацию кнопки - объект не отображается
//       this.classList.remove('active');
//     }
//     // alert('objName=' + objName + ' stateNumber' + objStateNumber + ' id=' + dataId + ' lat=' + lat + ' lon=' + lon);
//   });
// }

function addTargetSpanShowCalc(event) {
  if (mobileEvents.isTargetTouch) {
    return;
  }
  let x = event.clientX;
  let y = event.clientY;
  let { targetShowSpan } = this.detail;
  if (!targetShowSpan) return;

  let windowWidth = window.innerWidth;

  if (windowWidth > 767) {
    let rightSpace = windowWidth - x - 15;
    targetShowSpan.style.maxWidth = rightSpace + 'px';

    let elemHeight = targetShowSpan.offsetHeight;
    let windowHeight = window.innerHeight;
    let targetSpanBottomX = y - 15 + elemHeight;
    if (targetSpanBottomX > windowHeight) {
      targetSpanBottomX = windowHeight - 15;
    }
    targetShowSpan.style.top = targetSpanBottomX - elemHeight + 'px';
    targetShowSpan.style.left = x + 15 + 'px';
  } else {
    // from mobile
    targetShowSpan.style.left = 2 + 'px';
    targetShowSpan.style.width = windowWidth - 20 + 'px';

    let elemHeight = targetShowSpan.offsetHeight;
    let windowHeight = window.innerHeight;
    if (windowHeight / 2 > y) {
      // show on bottom
      targetShowSpan.style.top = y - elemHeight - 7 + 'px';
    } else {
      // show on top
      targetShowSpan.style.top = y - elemHeight - 5 + 'px';
    }
  }
}

function getTargetShowSpan(targetShow) {
  // return targetShowSpan
  if (targetShow.hasAttribute('data-target')) {
    return document.getElementById(targetShow.getAttribute('data-target'));
  } else {
    let targetShowSpans = targetShow.getElementsByClassName('target-show-span');
    if (!targetShowSpans[0]) return false;
    return targetShowSpans[0];
  }
}

function addTargetSpanShow(targetShow) {
  let targetShowSpan = getTargetShowSpan(targetShow);
  if (!targetShowSpan) return;

  mobileEvents.targetShowTouch(targetShowSpan);

  targetShow.addEventListener('mouseenter', function () {
    targetShowSpan.classList.add('hover');
  });

  targetShow.addEventListener('mouseleave', function () {
    if (!mobileEvents.isTargetTouch) {
      targetShowSpan.classList.remove('hover');
      return;
    }
    // для мобильных устройств обрабатывается событие mouseleave с задержкой
    setTimeout(function () {
      targetShowSpan.classList.remove('hover');
    }, 10);
  });

  targetShow.addEventListener('mousemove', {
    handleEvent: addTargetSpanShowCalc,
    detail: { targetShowSpan },
  });

  targetShow.addEventListener('change', {
    handleEvent: addTargetSpanShowCalc,
    detail: { targetShowSpan },
  });
}

function objectsTableEvent(event) {
  const { target: targetElement } = event;
  const row = targetElement.closest('tr.object-list-row');
  if (!row) {
    return;
  }
  const objectId = +row.id;
  if (!objectId) {
    return;
  }
  const objectName = row.dataset.name;
  const clearTrakElement = targetElement.closest(
    '[data-element="track-clear"]',
  );
  let trackGetElement = targetElement.closest('[data-element="track-get"]');
  const showOnMapElement = targetElement.closest(
    '[data-element="show-on-map"]',
  );
  let targetGetShowElement, trackGetSquareElement;
  if (clearTrakElement) {
    // // удалить трек
    // const clearTrakShowElement =
    //   clearTrakElement.querySelector('.target-show-span');
    // trackGetElement = row.querySelector('[data-element="track-get"]');
    // // const trackGetTatrgetElement = trackGetElement.querySelector('.target-show-span');
    // if (!globalObjects.globalPositions[objectId]) {
    //   return;
    // }

    // trackDeleteFunction = function () {
    //   if (
    //     leafletMain.leafletClearPositions(objectId, objectName, globalObjects)
    //   ) {
    //     // clear style
    //     trackGetSquareElement = trackGetElement.querySelector('.getPositions');
    //     trackGetElement.style.borderBottom = '';
    //     trackGetSquareElement.style.background = 'black';
    //     targetGetShowElement =
    //       trackGetElement.querySelector('.target-show-span');
    //     infoShowText_helper(targetGetShowElement, 'Запросить позиции');
    //     infoShowText_helper(clearTrakShowElement, 'Очистить трек на карте');
    //     // clear into list
    //     removeSelectOption(
    //       document.getElementById('positionsList'),
    //       '[value="' + objectId + '"]',
    //     );
    //   } else {
    //     infoShowText_helper(
    //       clearTrakShowElement,
    //       'ошибка удаления трека, попробуйте обновить страницу...',
    //     );
    //   }
    //   // showHiddenWindow('none');
    // };

    trackDeleterSetText(`Удалить трек по объекту ${objectName}?`);
    showHiddenWindow('block', 'track-delete-confirm');

    // return;
  }
  if (trackGetElement) {
    // targetGetShowElement = trackGetElement.querySelector('.target-show-span');
    // trackGetSquareElement = trackGetElement.querySelector('.getPositions');
    // if (event.ctrlKey && event.altKey) {
    //   callVendorPositions(
    //     objectId,
    //     trackGetSquareElement,
    //     targetGetShowElement,
    //   );
    // } else {
    //   callOurPositions(
    //     objectId,
    //     trackGetSquareElement,
    //     targetGetShowElement,
    //     true,
    //   );
    // }
    // return;
  }
  if (showOnMapElement) {
    let objData = {};
    objData.dataId = objectId;
    // let trObject = document.getElementById(obj);

    objData.name = objectName;
    objData.stateNumber =
      row.getElementsByClassName('state-number')[0].textContent;
    objData.lat = row.getElementsByClassName('lat-on-map')[0].textContent;
    objData.lon = row.getElementsByClassName('lon-on-map')[0].textContent;
    objData.speed = row.getElementsByClassName('speed-on-map')[0].textContent;
    objData.head = row.getElementsByClassName('head-on-map')[0].textContent;
    objData.lastPosTime =
      1000 * row.getElementsByClassName('lastPosTime-on-map')[0].textContent;

    const showOnMapSquareElement = showOnMapElement.querySelector('.showOnMap');
    if (leafletMain.leafletAddObjPositions(objData, false)) {
      // активировать кнопку - объект на карте отображается
      showOnMapSquareElement.classList.add('active');
    } else {
      // снять активацию кнопки - объект не отображается
      showOnMapSquareElement.classList.remove('active');
    }
    // alert('objName=' + objName + ' stateNumber' + objStateNumber + ' id=' + dataId + ' lat=' + lat + ' lon=' + lon);
  }
}

function addEventClickOnItemClearPositions(itemElement) {
  itemElement.addEventListener('click', function () {
    let dataId = +this.getAttribute('data-id');

    if (!(dataId > 0)) {
      let targetE = getNeighborByClass_helper(this, 'target-show-span');
      infoShowText_helper(
        targetE,
        'ошибка запроса, попробуйте обновить страницу...',
      );
      return;
    }

    let objRow = document.getElementById(dataId);
    let objName = objRow.getAttribute('data-name');

    if (leafletMain.leafletClearPositions(dataId, objName, globalObjects)) {
      // clear style
      let item = document.getElementById('get-pos-' + dataId);
      let child = item.querySelector('.getPositions');
      item.style.borderBottom = '';
      child.style.background = 'black';
      let targetE = item.querySelector('.target-show-span');
      infoShowText_helper(targetE, 'Запросить позиции');

      // clear into list
      removeSelectOption(
        document.getElementById('positionsList'),
        '[value="' + dataId + '"]',
      );
    } else {
      infoShowText_helper(
        targetE,
        'ошибка удаления трека, попробуйте обновить страницу...',
      );
    }
  });
}

function getObjName(positions) {
  var code = -1;
  var name = 'null';
  for (var i = 0; i < positions.length; i++) {
    if (positions[i][0]) {
      code = positions[i][0];
      break;
    }
  }
  //objectsList берется из html при рендеринге через php
  if (code in objectsList) {
    name = objectsList[code]['name'];
  }
  return name;
}

function treatmentResponseVendor(response, targetElement, objId) {
  const jsonRes = JSON.parse(response);

  if (!jsonRes) {
    infoShowText_helper(targetElement, 'Ошибка: получен НЕ JSON');
    return;
  }
  var errMsg = false;
  if ((errMsg = getErrorResponse(jsonRes))) {
    infoShowText_helper(targetElement, errMsg);
    return;
  }
  var infoForShow = '';
  if (jsonRes['info']) {
    infoForShow = String(jsonRes['info']).replace('<br>', '');
  } else {
    infoForShow = 'данные загружены, поле info в ответе отсутствует';
  }
  const positions = JSON.parse(jsonRes['response']);
  if (!positions) {
    infoForShow += ' / позиции как json получить не удалось';
    infoShowText_helper(targetElement, infoForShow);
    return;
  }
  infoForShow += ' / получено ' + positions.length + ' позиций';
  let objName = getObjName(positions);

  infoShowText_helper(targetElement, infoForShow + ' / по объекту ' + objName);

  let codifier = getParamsCodifier();
  let points = getPointsFromVendor(positions, codifier);
  if (points['latlngs'].length === 0) {
    infoShowText_helper(targetElement, 'позиций с координатами не обнаружено');
    return;
  }
  leafletMain.leafletAddPolyline(
    points,
    'Трек по объекту ' + objName,
    objId,
    globalObjects,
  );
  leafletMain.addViolationsOnMap({}, objId, objName, 'gearboxName', [], true);
}

function treatmentResponseOurAndSave(response, timeCheck, targetElement) {
  // сохранение позиций и вставка трека на карту
  if (
    !('objId' in response) ||
    !('objName' in response) ||
    !('positions' in response)
  ) {
    infoShowText_helper(
      targetElement,
      'Ошибка: ответ получен, но он невалидный !!!',
    );
    return;
  }

  infoShowText_helper(targetElement, 'Данные получены, обрабатываю ...');
  let positions = response['positions'];

  let infoForShow = '';
  infoForShow =
    'По объекту ' + response['objName'] + ' получено ' + positions.length;
  infoForShow += ' позиций с сервера за период с ';
  let trackBegin = formatDateHelper(
    new Date(response['getBegin'] * 1000),
    'dd.mm.yyyy hh:nn:ss',
  );
  let trackEnd = formatDateHelper(
    new Date(response['getEnd'] * 1000),
    'dd.mm.yyyy hh:nn:ss',
  );
  infoForShow += trackBegin + ' по ' + trackEnd;
  let objName = response['objName'];
  let gearboxName = response['gearboxName'];
  let objId = response['objId'];

  const objectRow = document.getElementById(objId);
  const stateNumber = objectRow.querySelector('.search-stateNumber').innerText;

  let points = getPointsFromOur(positions, objName, gearboxName);
  let trackInfo =
    'Трек по объекту ' +
    objName +
    ' ' +
    stateNumber +
    '<br>с ' +
    trackBegin +
    ' по ' +
    trackEnd;

  var queryEnd = new Date();
  const queryInterval = (queryEnd - timeCheck.begin) / 1000;
  //alert(queryInterval);
  // infoForShow += ' (запрос выполнен за ' + roundNumber_helper(queryInterval, 1) + ' секунд, php memory_get_peak_usage_summ = ' +
  //               (response['memory_get_peak_usage_summ']) + ' Mb)';
  infoForShow += ` (запрос выполнен за ${roundNumber_helper(
    queryInterval,
    1,
  )} секунд)`;
  infoShowText_helper(targetElement, infoForShow);

  // add into positionsList
  let positionsList = document.getElementById('positionsList');
  let html =
    '<option selected value="' +
    objId +
    '">' +
    objName +
    '(' +
    trackBegin +
    ' - ' +
    trackEnd +
    ')' +
    '</option>';
  removeSelectOption(positionsList, '[value="' + objId + '"]'); // если ранее запрос уже был выполнен - удалим его
  positionsList.innerHTML += html;

  removeSelectOption(positionsList, '[value="none"]');

  // violations list setting - меню нарушений на карте заполнение
  if (!(objId in globalObjects.violationsSettingsList)) {
    // globalObjects.violationsSettingsList[objId] = getFirstViolationsListSetting(violationsDescriptions._desc);
    // по умолчанию отключаем отображение нарушений на карте
    globalObjects.violationsSettingsList[objId] = {};
  }
  violationsSettingListInnerHtml(
    violationsDescriptions._desc,
    globalObjects.violationsSettingsList[objId],
    points['violations_stat'],
  );

  // leafletMain.leafletAddPolyline(points, trackInfo, objId, objName, gearboxName, globalObjects.violationsSettingsList[objId]);
  leafletMain.leafletAddPolyline(points, trackInfo, objId, globalObjects);
  leafletMain.addViolationsOnMap(
    points,
    objId,
    objName,
    gearboxName,
    globalObjects.violationsSettingsList[objId],
    false,
  );
  // addEventsViolationsList();

  globalObjects.globalPositions[objId] = {
    points,
    trackInfo,
    infoForShow,
    getBegin: response['getBegin'],
    getEnd: response['getEnd'],
    trackBegin,
    trackEnd,
    stateNumber,
    objName,
  };

  // delete points;
}

function violationsSettingListInnerHtml(
  violationsDescriptionsDesc,
  violationsSettingsObject,
  violationsStat,
) {
  // add into html
  let violationsDescriptionList = getViolationsDescriptionList(
    violationsDescriptionsDesc,
    violationsSettingsObject,
    violationsStat,
  ); // html text
  document.getElementById('violations-setting').innerHTML =
    violationsDescriptionList;
}

// function addEventsViolationsList() {
//   // let violationsSettingList = document.getElementById('violations-setting-list');
//   let violationsSettingCheckboxes = document.querySelectorAll('#violations-setting-list [type="checkbox"]');
//   for (let i = 0; i < violationsSettingCheckboxes.length; i++) {
//     violationsSettingCheckboxes[i].addEventListener("change", changeViolationsSetting);
//   }
// }

function getFirstViolationsListSetting(violationsDescriptionsDesc) {
  let res = {};
  for (let i = 0; i < violationsDescriptionsDesc.length; i++) {
    res[violationsDescriptionsDesc[i]['id']] = true;
  }
  return res;
}

function removeSelectOption(selectList, childSelector) {
  // remove option
  let childOption = selectList.querySelector(childSelector);
  if (childOption) {
    selectList.removeChild(childOption);
  }
  // add default option
  if (!selectList.options.length) {
    document.getElementById('violations-setting').innerHTML =
      'Меню нарушений на карте (нет запросов).';
    selectList.innerHTML += '<option value="none">Нет запросов</option>';
  } else {
    // проверить какой объект выводится - и по нему вывести настройку отображения
  }
}

function getErrorResponse(jsonRes) {
  if (jsonRes['error']) {
    return jsonRes['error'];
  }
  if (!jsonRes['response']) {
    return 'Ошибка: пустой response';
  }
}

function getValue(arr, key, notVal) {
  if (key in arr) {
    return arr[key];
  }
  return notVal;
}

function getViolationsDescriptionList(
  violationsDescriptionsDesc,
  violationsSetting,
  violationsStat,
) {
  // let violationsDescriptionListTemplate = document.getElementById(
  //   'violations-description-list-template',
  // ).innerHTML;
  // let compiled = _.template(violationsDescriptionListTemplate);
  const compiled = _.template(violationsDescriptionListHtml);
  let html = compiled({
    data: violationsDescriptionsDesc,
    violationsSetting: violationsSetting,
    violationsStat: violationsStat,
  });
  return html;
  // document.getElementById('section-reports').innerHTML = html;
}

function getPointsFromOur(positions, objName, gearboxName) {
  const points = {
    objName,
    gearboxName,
    latlngs: [],
    params: [], // values: [], пишется в params
    allValues: positions, // для графиков
    violations_stat: {
      // статистика по количеству нарушений
      summ: 0, // сумма всех нарушений
    },
    violations_text: [],
    violations_values: [],
  };
  // points['objName'] = objName;
  // points['gearboxName'] = gearboxName;
  // points['latlngs'] = [];
  // points['params'] = [];
  // points['values'] = [];
  // points['violations_stat'] = {}; // статистика по количеству нарушений
  // points['violations_stat']['summ'] = 0; // сумма всех нарушений
  // points['violations_text'] = [];
  // points['violations_values'] = [];

  let column = 0;
  let violation_id = [];
  let violation_text = [];
  let violation_values = [];

  let posIndex = 0;
  let maxSpeed = 0;

  let distSumm = 0;
  let distPrev = -1;
  let distBegin = -1;
  let timeWithDist = 0;
  let curSpeed = 0;

  for (let k = 0; k < positions.length; k++) {
    const {
      time,
      lat = 0,
      lon = 0,
      head,
      speed,
      violation_1,
      violation_2,
      violation_3,
      clutch_time_uninterruptedly,
      pto_cnt_violation,
      spd_accel,
      gps = 0,
      dist,
      is_last_day_pos,
      back_raised,
      back_raised_cnt_violation
    } = positions[k];

    if (speed < 2500) {
      // 250 км/ч
      curSpeed = speed;
    }

    const canDistProtocolLimit = 21055406000; // в метрах
    const isDist = Boolean(dist && dist < canDistProtocolLimit);

    if (isDist && distBegin === -1) {
      distBegin = dist;
      distPrev = dist;
    }

    if (isDist) {
      let dSummTemp = dist > distPrev ? dist - distBegin : distPrev - distBegin;
      if (timeWithDist && dSummTemp && dSummTemp > distSumm) {
        const timeDiff = time - timeWithDist;
        if (!timeDiff) {
          timeDiff = 1000;
        }
        const avgSpdTemp = (dSummTemp - distSumm) / 1000 / (timeDiff / 3600000);
        if (avgSpdTemp > 250) {
          dSummTemp = distSumm;
        }
      }

      timeWithDist = time;

      // distSumm = (dist > distPrev) ? dist - distBegin : distPrev - distBegin;
      distSumm = dSummTemp;
      distPrev = dist;
    }

    positions[k].distSumm = distSumm;

    if (!(lat > 0 && lon > 0)) {
      continue;
    }

    column = 0;
    violation_id = [];
    violation_text = [];
    violation_values = '';

    while ('violation_' + Number(++column) in positions[k]) {
      const col_name = 'violation_' + column;

      if (!(positions[k][col_name] > 0)) {
        continue;
      }

      for (let i = 0; i < 31; i++) {
        if (positions[k][col_name] & (1 << i)) {
          let viol_id = i + 1 + 31 * (column - 1);
          violation_id.push(viol_id);
          let violation_desc = getVolationDesc_helper(
            viol_id,
            violationsDescriptions._desc,
          );
          violation_text.push(violation_desc['description']);
          //clutch_time_uninterruptedly, pto_cnt_violation, spd_accel

          if (viol_id in points['violations_stat']) {
            points['violations_stat'][viol_id]++;
          } else {
            points['violations_stat'][viol_id] = 1;
          }

          points['violations_stat']['summ']++;
        }
      }
      
      violation_values = {
        clutch_time_unine: clutch_time_uninterruptedly,
        pto_cnt_violation,
        spd_accel,
        spd: curSpeed,
        back_raised_cnt_violation
      };
    }

    if (curSpeed > maxSpeed) {
      maxSpeed = curSpeed;
    }

    points['latlngs'][posIndex] = [
      lat / 1000000000000000,
      lon / 1000000000000000,
    ];

    points['params'][posIndex] = {
      viewTime: time / 1000,
      speed: curSpeed,
      violation_1,
      violation_2,
      violation_3,
      violation_id: violation_id.join(';'),
      violation_text: violation_text.join(';<br>'),
      violation_values: violation_values,
      head: head,
      gps,
      maxSpeed,
      violationsSumm: points['violations_stat']['summ'],
    };

    posIndex++;
  }

  return points;
}

function getPointsFromVendor(positions, codifier) {
  var points = [];
  points['latlngs'] = [];
  points['params'] = [];
  var point = [];
  var pos;
  var viewTime;
  var speed;
  var notVal = 'н.д.';
  for (var i = 0; i < positions.length; i++) {
    pos = getPosParamsFromVendor(positions[i], codifier, i);

    if (typeof pos !== 'object') {
      continue;
    }
    if (!('lon' in pos) || !('lat' in pos)) {
      continue;
    }

    if (!pos['lon'] || !pos['lat']) {
      continue;
    }

    viewTime = getValue(pos, 'viewTime', notVal);
    speed = getValue(pos, 'speed', notVal);

    points['params'].push({
      viewTime: viewTime,
      speed: speed,
    });

    points['latlngs'].push([pos['lat'], pos['lon']]); //for leaflet map
  }

  return points;
}

function getPosParamsFromVendor(viewPos, codifier, index) {
  if (typeof viewPos !== 'object') {
    return [];
  }
  /*проверка, что есть нужные параметры*/
  if (
    !(0 in viewPos) ||
    !(1 in viewPos) ||
    !(2 in viewPos) ||
    !(4 in viewPos)
  ) {
    return [];
  }
  //время
  var resParams = [];
  resParams['viewTime'] = viewPos[1];
  var params = viewPos[4]; //прочие параметры позиции
  var fCode;
  var settingNameParam;
  var val;
  var param;
  var key;
  /* перебор параметров*/
  //for(var i = 0; i < params.length; i++){
  for (key in params) {
    param = params[key];
    if (typeof param !== 'object') continue;
    /* param[0] - ключ(по кодификатору), param[1] - значение*/
    if (!(0 in param) || !(1 in param)) continue;
    /* берем код параметра из файла (как по API получено)*/
    fCode = param[0];
    /* если нам этот код неизвестен / не нужен - пропускаем его*/
    if (!(fCode in codifier)) continue;
    /* переводим имя параметра в удобное*/
    settingNameParam = codifier[fCode];
    /* получаем значение параметра*/
    val = param[1];
    /* добавляем в массив всех параметров*/
    if (typeof settingNameParam === 'object') {
      if (!(settingNameParam[0] in resParams)) {
        resParams[settingNameParam[0]] = {};
      }
      resParams[settingNameParam[0]][settingNameParam[1]] = val;
    } else {
      resParams[settingNameParam] = val;
    }
  }
  return resParams;
}

let resizeY = {
  handleEvent: function (event, elem, resizeElem) {
    if (!isMouseDown) this.isMouseDown = false;
    let getBoundingThis = elem.getBoundingClientRect();

    if (event.clientY > getBoundingThis.top + 10 && !this.isMouseDown) {
      this.endResize();
      return;
    }

    let method = 'on' + event.type[0].toUpperCase() + event.type.slice(1);
    this[method](event, elem, resizeElem);
  },

  onMouseup: function (e, elem, resizeElem) {
    this.isMouseDown = false;
  },

  onMousedown: function (e, elem, resizeElem) {
    this.isMouseDown = true;

    this.lastElementY = e.clientY;

    let getBoundingThis = elem.getBoundingClientRect();
    this.lastElementHeight = getBoundingThis.height;

    let getBoundingResize = resizeElem.getBoundingClientRect();
    this.lastResizeHeight = getBoundingResize.height;
  },

  onMousemove: function (e, elem, resizeElem) {
    if (!this.isMouseDown) return;

    let changed = e.clientY - this.lastElementY;
    let elemNewHeight = this.lastElementHeight - changed;
    let resizeElemNewHeight = this.lastResizeHeight + changed;

    if (elemNewHeight < 10 || resizeElemNewHeight < 10) {
      this.lastElementY = e.clientY;
      return;
    }

    elem.style.height =
      Math.floor(
        (100 * elemNewHeight) / document.documentElement.clientHeight,
        1,
      ) + 'vh';
    resizeElem.style.height =
      Math.floor(
        (100 * resizeElemNewHeight) / document.documentElement.clientHeight,
        1,
      ) + 'vh';

    this.lastElementHeight = elemNewHeight;
    this.lastResizeHeight = resizeElemNewHeight;

    this.lastElementY = e.clientY;
  },

  changeCursorY: function (e, elem) {
    let getBoundingThis = elem.getBoundingClientRect();

    if (e.clientY < getBoundingThis.top + 10) {
      elem.style.cursor = 's-resize';
    } else {
      elem.style.cursor = 'default';
    }
  },

  endResize: function () {
    this.isMouseDown = false;
  },

  isMouseDown: false,
  lastElementY: 0,
  lastElementHeight: 0,
  lastResizeHeight: 0,
};

// function datepickerInitialization() {
//   // const todayInterval = getTodayIntervalAsString_helper();

//   // const beginTime = document.getElementById('beginTime');
//   // const endTime = document.getElementById('endTime');

//   // const beginTimeGraphics = document.getElementById('beginTimeGraphics');
//   // const endTimeGraphics = document.getElementById('endTimeGraphics');

//   // beginTime.value = todayInterval.timeBegin;
//   // endTime.value = todayInterval.timeEnd;

//   // beginTimeGraphics.value = todayInterval.timeBegin;
//   // endTimeGraphics.value = todayInterval.timeEnd;

//   // $('#beginTime').datepicker({
//   //   timepicker: 'true',
//   //   language: 'ru',
//   //   'data-timeFormat': 'hh:ii AA',
//   //   position: 'bottom left',
//   //   todayButton: new Date(todayInterval.timeBeginUnix),
//   //   startDate: new Date(todayInterval.timeBeginUnix),
//   //   altField: '#beginTime-altField',
//   //   autoClose: true
//   // });
//   // // let myDatepicker = $('#beginTime').datepicker().data('datepicker');
//   // // // Обновление нескольких параметров
//   // // myDatepicker.update({
//   // //   date: new Date(todayInterval.timeBeginUnix)
//   // // });
//   // // myDatepicker.show();

//   // $('#endTime').datepicker({
//   //   timepicker: 'true',
//   //   language: 'ru',
//   //   timeFormat: 'hh:ii',
//   //   position: 'bottom center',
//   //   todayButton: new Date(todayInterval.timeEndUnix),
//   //   altField: '#endTime-altField',
//   //   autoClose: true
//   // });

//   // $('#beginTimeGraphics').datepicker({
//   //   timepicker: 'true',
//   //   language: 'ru',
//   //   'data-timeFormat': 'hh:ii AA',
//   //   position: 'bottom left',
//   //   todayButton: new Date(todayInterval.timeBeginUnix),
//   //   startDate: new Date(todayInterval.timeBeginUnix),
//   //   altField: '#beginTime-altField',
//   //   autoClose: true,
//   // });

//   // $('#endTimeGraphics').datepicker({
//   //   timepicker: 'true',
//   //   language: 'ru',
//   //   timeFormat: 'hh:ii',
//   //   position: 'bottom center',
//   //   todayButton: new Date(todayInterval.timeEndUnix),
//   //   altField: '#endTime-altField',
//   //   autoClose: true,
//   // });
// }

// function showHiddenWindow(state, idElement){
//   // 'template-setting-window', 'logout-window'
//   if (state == 'none') {
//     document.getElementById('background-wrap').style.display = state;
//     document.getElementById('template-setting-window').style.display = state;
//     document.getElementById('logout-window').style.display = state;
//   }	else {
//     document.getElementById('background-wrap').style.display = state;
//     document.getElementById(idElement).style.display = state;
//   }
// }

export function showHiddenWindow(state, idElement) {
  // 'template-setting-window', 'logout-window', 'skills-man-detail-template-setting'
  if (state == 'none') {
    document.getElementById('background-wrap').style.display = state;
    if (idElement) {
      document.getElementById(idElement).style.display = state;
    }
    document.getElementById('logout-window').style.display = state;
    document.getElementById('logout-window-confirm').style.display = state;
    document.getElementById('switch-window-confirm').style.display = state;
    document.getElementById('track-delete-confirm').style.display = state;
    document.getElementById('shiftWork-template-setting-window').style.display =
      state;
    document.getElementById(
      'skills-man-detail-template-setting',
    ).style.display = state;
  } else {
    if (idElement !== 'logout-window') {
      document.getElementById('logout-window').style.display = '';
    }
    document.getElementById('background-wrap').style.display = state;
    document.getElementById(idElement).style.display = state;
  }
}
