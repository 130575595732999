<template>
  <my-modal
    v-show="props.show"
    @close="closeHandler"
  >
    <template #header>
      <div style="width: 100%; display: flex; justify-content: space-between">
        <button
          v-show="isReady"
          class="not-print float-left export-button"
          style="max-height: 40px"
          onclick="html_to_excel_helper.export( this, 'skills-man-detail-template-content-modal', 'Навыки MAN детализация', 'landscape' )"
        >
          <span class="mobile">Экспорт&nbsp;в&nbsp;</span>EXCEL
        </button>

        <h5
          id="violations-details-modal-target"
          class="text-center footer-desc title"
          style="margin: 0 10px"
          v-html="title"
        ></h5>

        <ui-close-button @click="closeHandler" />
      </div>
    </template>
    <template #body>
      <div id="violations-details-modal-section-top"></div>
      <div
        v-if="!isReady"
        class="preloader"
      >
        <preloader />
      </div>
      <div
        v-else
        id="violations-details-modal-section"
      ></div>
    </template>
    <template
      v-if="true"
      #footer
    >
      <ui-pagination
        :page-number="pageNumber"
        :pages-length="pagesLength"
        :href="'#violations-details-modal-section-top'"
        @change-value="violationsDetailsRenderHandler"
      />
    </template>
  </my-modal>
</template>

<script setup>
import { inject, nextTick, onMounted, ref, watch } from '@vue/runtime-core';

import MyModal from '../../../Components/MyModal.vue';
import UiPagination from '@/App/Components/UiPagination.vue';
import UiCloseButton from '@/App/Components/Buttons/UiCloseButton.vue';
import Preloader from '@/App/Components/Preloader.vue';

import { getSkillsManDetailTemplateHtml } from '../../../TemplateComponents/SkillsManDetail/SkillsManDetailTemplateHtml';
import {
  ajaxPositionsDoneAll,
  callOurPositions,
  getCallOurPosotionsPrepareDataSends,
  preparingDataFromGetPos,
  url_get_positions,
} from '../../../use/getElementPosition';
import fetchJson from '@/src/dataRequest/fetch-json';
import { violationsDescriptions } from '@/src/dataRequest/mainScript';
import SkillsManDetailCalculate from '@/Template/skills_man_template/SkillsManDetailCalculate';

const userData = inject('userData');

const props = defineProps({
  show: { type: Boolean, default: true },
  isCriticalViolations: { type: Boolean, default: false },
  objId: { type: String, default: '' },
  interval: { type: Array, default: [] },
  violations: { type: Array, default: [] },
  skill: { type: String, default: '' },
  paramsIndex: { type: [String, Number], default: 0 },
  countOfPositionsShownTop: { type: Number, default: 0 },
  countOfPositionsShownBottom: { type: Number, default: 0 },
});

const emit = defineEmits(['close']);

const PAGES_INTERVAL = 86400000;

const isReady = ref(false);

const title = ref('');

const offsetPositions = ref({ up: 0, down: 0 });

const paginationArray = ref([]);
const pageNumber = ref(0);
const pagesLength = ref(0);

const skillsManDetails = ref({});

const projectData = inject('projectData');
const globalObjectsList = inject('globalObjectsList');

const getPagination = () => {
  const { detailViolationRows } = skillsManDetails.value;

  const rows = detailViolationRows;

  const acc = {
    positionInterval: [
      {
        positionBegin: { arrIndex: 0, posIndex: 0, time: +props.interval[0] },
        positionEnd: { arrIndex: 0, posIndex: 0, time: 0 },
      },
    ],
    index: 0,
  };

  const addInterval = (
    [beginArrIndex = 0, beginPosIndex = 0, beginTime = 0],
    [endArrIndex = 0, endPosIndex = 0, endTime = 0],
  ) => {
    acc.positionInterval.push({
      positionBegin: {
        arrIndex: beginArrIndex,
        posIndex: beginPosIndex,
        time: beginTime,
      },
      positionEnd: {
        arrIndex: endArrIndex,
        posIndex: endPosIndex,
        time: endTime,
      },
    });
  };

  const setPosInterval = ({ name, arrIndex, posIndex, time }) => {
    acc.positionInterval[acc.index][name].arrIndex = arrIndex;
    acc.positionInterval[acc.index][name].posIndex = posIndex;
    acc.positionInterval[acc.index][name].time = time;
  };

  for (let i = 0; i < rows.length; i++) {
    const row = rows[i];

    for (let j = 0; j < row.length; j++) {
      const obj = row[j];

      const { positionBegin, positionEnd } = acc.positionInterval[acc.index];

      const lastPositionBeginTime = positionBegin.time;

      const lastPositionTimeWithPagesInterval =
        lastPositionBeginTime + PAGES_INTERVAL;

      let beginTime;
      if (lastPositionTimeWithPagesInterval > +props.interval[1]) {
        beginTime = +props.interval[1];
      } else if (lastPositionTimeWithPagesInterval > +obj.DTime) {
        const objDate = new Date(obj.DTime);
        beginTime = new Date(
          objDate.getFullYear(),
          objDate.getMonth(),
          objDate.getDate() + 1,
        ).getTime();
      } else {
        beginTime = lastPositionTimeWithPagesInterval;
      }

      const isLastIteration = i === rows.length - 1 && j === row.length - 1;

      if (isLastIteration) {
        setPosInterval({
          name: 'positionEnd',
          arrIndex: i,
          posIndex: j,
          time: beginTime,
        });

        if (beginTime < +props.interval[1]) {
          addInterval([0, 0, beginTime], [0, 0, +props.interval[1]]);
        }
        break;
      }

      if (obj.DTime - lastPositionTimeWithPagesInterval > PAGES_INTERVAL) {
        beginTime +=
          PAGES_INTERVAL *
          Math.floor(
            (obj.DTime - lastPositionTimeWithPagesInterval) / PAGES_INTERVAL,
          );

        setPosInterval({
          name: 'positionEnd',
          arrIndex: i,
          posIndex: j,
          time: beginTime,
        });

        addInterval([i, j, beginTime], [0, 0, beginTime]);

        acc.index++;
        continue;
      }

      if (obj.DTime > lastPositionTimeWithPagesInterval) {
        setPosInterval({
          name: 'positionEnd',
          arrIndex: i,
          posIndex: j,
          time: beginTime,
        });

        addInterval([i, j, beginTime], [0, 0, beginTime]);

        acc.index++;
      }
    }
  }

  if (acc.positionInterval.length > 1) {
    const intervalsLength = acc.positionInterval.length;

    // Если нет нарушений на первых страницах удалим их
    // но сохраним начало интервала
    let lastPageIndex = intervalsLength - 1;

    for (let index = intervalsLength - 1; index > -1; index--) {
      const { positionEnd } = acc.positionInterval[index];

      if (positionEnd.arrIndex !== 0 || positionEnd.posIndex !== 0) {
        lastPageIndex = index;
        break;
      }
    }

    if (lastPageIndex !== intervalsLength - 1) {
      acc.positionInterval[lastPageIndex].positionEnd.time =
        acc.positionInterval[intervalsLength - 1].positionEnd.time;

      for (let num = 0; num < intervalsLength - 1 - lastPageIndex; num++) {
        acc.positionInterval.pop();
      }
    }

    // Если нет нарушений на последних страницах удалим их
    // но сохраним конец интервала
    let firstPageIndex = 0;

    for (let index = 0; index < intervalsLength; index++) {
      const { positionEnd } = acc.positionInterval[index];

      if (positionEnd.arrIndex !== 0 || positionEnd.posIndex !== 0) {
        firstPageIndex = index;
        break;
      }
    }

    if (firstPageIndex !== 0) {
      acc.positionInterval[firstPageIndex].positionBegin.time =
        acc.positionInterval[0].positionBegin.time;

      for (let num = 0; num < firstPageIndex; num++) {
        acc.positionInterval.shift();
      }
    }
  }

  return acc.positionInterval;
};

const getReportSettings = () => {
  const detailViolationsSetting = {
    ids: [],
    isAll: false,
  };

  const violationsDescriptionsDesc = violationsDescriptions._desc;
  const violationsDescriptionsSpnErrors = violationsDescriptions._spnErrors;

  let allPosesInDetailElementChecked = true;
  if (props.violations.length) {
    detailViolationsSetting.ids = props.violations.map((objectData) => ({
      id: +objectData.id,
      desc: objectData.description,
    }));

    detailViolationsSetting.isAll = false;

    allPosesInDetailElementChecked = false;
  } else {
    detailViolationsSetting.ids = violationsDescriptionsDesc.map(
      (objectData) => ({
        id: +objectData.id,
        desc: objectData.description,
      }),
    );

    detailViolationsSetting.isAll = true;
  }

  return {
    violationsDescriptionsDesc,
    violationsDescriptionsSpnErrors,
    detailViolationsSetting,
    morePositionsCnt: 5,
    detailPosCntElementValue: 5,
    allPosesInDetailElementChecked,
  };
};

const getDetailViolationRows = ({
  positionBegin,
  positionEnd,
  detailViolationRows,
}) => {
  const violationRows = [];

  for (let i = positionBegin.arrIndex; i < positionEnd.arrIndex + 1; i++) {
    const startPosIndex =
      i === positionBegin.arrIndex ? positionBegin.posIndex : 0;

    const lastPosIndex =
      i === positionEnd.arrIndex
        ? positionEnd.posIndex
        : detailViolationRows[i].length - 1;

    if (!startPosIndex && !lastPosIndex) continue;
    const violationRow = [];

    for (let j = startPosIndex; j < lastPosIndex + 1; j++) {
      if (!detailViolationRows[i][j]) continue;

      violationRow.push(detailViolationRows[i][j]);
    }

    violationRows.push(violationRow);
  }

  return violationRows;
};

const getPositionsInterval = (num) => {
  if (!props.isCriticalViolations) {
    if (num < 0) {
      num = paginationArray.value.findIndex((obj) => {
        return (
          obj.positionBegin <= +props.paramsIndex &&
          obj.positionEnd >= +props.paramsIndex
        );
      });

      const { positionBegin = 0, positionEnd = 0 } = paginationArray.value[num];

      const paramsIndex = +props.paramsIndex;

      const countPositionsUp = positionBegin - paramsIndex;
      const countPositionsDown = positionBegin - paramsIndex;

      if (
        countPositionsUp !== props.countOfPositionsShownTop ||
        countPositionsDown !== props.countOfPositionsShownBottom
      ) {
        offsetPositions.value.up =
          countPositionsUp + props.countOfPositionsShownTop;

        offsetPositions.value.down =
          countPositionsDown + props.countOfPositionsShownBottom;
      }
      pageNumber.value = num;
      return {
        positionBegin: positionBegin - offsetPositions.value.up,
        positionEnd: positionEnd - offsetPositions.value.down,
      };
    }

    pageNumber.value = num;
    return {
      positionBegin:
        paginationArray.value[num].positionBegin - offsetPositions.value.up,
      positionEnd:
        paginationArray.value[num].positionEnd - offsetPositions.value.down,
    };
  }

  if (num < 0) num = 0;
  pageNumber.value = num;
  return paginationArray.value[num];
};

const getDetailColumnsString = () => {
  let detailColumns = [];

  if (projectData.userRole === 'main_admin') {
    detailColumns = [
      'DTime',
      'DKren',
      'DTangage',
      'DPTOWorkBackRaised',
      'DBackRaisedCntViolation',
      'DCanDist',
      'DCanSpeed',
      'DCanGspd',
      'DInputSpeed',
      'DOutputSpeed',
      'DClutchSlip',
      'DClutchSlipSpeed',
      'DClutchWork',
      'DClutchWorkAvg',
      'DClutchWorkAvgLastInclusion',
      'DClutchWorkAvgPeriodSumm',
      'DClutchLastTimeTemperature',
      'DRotatingMoment',
      'DRotatingMomentMax',
      'DRotatingMomentValue', // 09.05.2020 - 19.06.2020
      // 'DAcceleration',
      'DEngineBreakes',
      'DBreakPosition',
      'DBreakPositionOn',
      'DHandBreak',
      'DCanEngineTemperature',
      'DCanAccelerate',
      'DCanRmp',
      'DCanLoad',
      'DCanClutch',
      // 'DCruiseControl',
      'DCanGearbox',
      'Dax0l',
      'Dax0r',
      'Dax1l',
      'Dax1r',
      'Dax2l',
      'Dax2r',
      'DSelectedGear',
      // 09.05.2020 'DGearRatioSpdRpm'
    ]; //столбцы детализации
  } else if (projectData.userRole === 'customer_admin') {
    detailColumns = [
      'DTime',
      'DKren',
      'DTangage',
      'DPTOWorkBackRaised',
      'DBackRaisedCntViolation',
      'DCanDist',
      'DCanSpeed',
      'DCanGspd',
      'DInputSpeed',
      'DOutputSpeed',
      'DClutchSlip',
      'DClutchSlipSpeed',
      'DClutchWork',
      'DClutchWorkAvg',
      'DClutchWorkAvgLastInclusion',
      'DClutchWorkAvgPeriodSumm',
      'DClutchLastTimeTemperature',
      'DRotatingMoment',
      'DRotatingMomentMax',
      'DRotatingMomentValue',
      'DEngineBreakes',
      'DBreakPosition',
      'DBreakPositionOn',
      'DHandBreak',
      'DCanEngineTemperature',
      'DCanAccelerate',
      'DCanRmp',
      'DCanLoad',
      'DCanClutch',
      'DCanGearbox',
      'Dax0l',
      'Dax0r',
      'Dax1l',
      'Dax1r',
      'Dax2l',
      'Dax2r',
    ]; //столбцы детализации
  } else {
    detailColumns = [
      'DTime',
      'DKren',
      'DTangage',
      'DPTOWorkBackRaised',
      'DBackRaisedCntViolation',
      'DCanDist',
      'DCanSpeed',
      'DCanGspd',
      'DInputSpeed',
      'DOutputSpeed',
      'DClutchSlip',
      'DClutchSlipSpeed',
      'DClutchWork',
      'DClutchWorkAvgLastInclusion',
      'DClutchLastTimeTemperature',
      'DRotatingMoment',
      'DRotatingMomentValue',
      'DEngineBreakes',
      'DBreakPosition',
      'DBreakPositionOn',
      'DHandBreak',
      'DCanEngineTemperature',
      'DCanAccelerate',
      'DCanRmp',
      'DCanLoad',
      'DCanClutch',
      'DCanGearbox',
      'Dax0l',
      'Dax0r',
      'Dax1l',
      'Dax1r',
      'Dax2l',
      'Dax2r',
    ];
  }

  let detailColumnsString = '';
  let punctuation = '';

  detailColumns.map((str) => {
    detailColumnsString += `${punctuation}'${str}'`;
    punctuation = ', ';
  });
  return detailColumnsString;
};

async function fetchDetails() {
  const targetElem = document.getElementById('violations-details-modal-target');

  const preparingDataArr = preparingDataFromGetPos(
    props.objId,
    targetElem,
    true,
    {
      timeBegin: +props.interval[0],
      timeEnd: +props.interval[1],
    },
  );

  getCallOurPosotionsPrepareDataSends(preparingDataArr);

  const objConf = globalObjectsList[props.objId];

  let queryNumber = 0;
  const promises = preparingDataArr.sends.map(async (preparingData) => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8',
      },
      body: JSON.stringify(preparingData),
    };

    const res = await fetchJson(url_get_positions, options);

    const percent = Math.floor(
      (queryNumber / preparingDataArr.sends.length) * 100,
    );
    title.value = `Загрузка детализации ${queryNumber} из ${preparingDataArr.sends.length} (${percent}%)`;

    queryNumber++;

    return res;
  });

  const positions = await Promise.all(promises);

  const piecesPositions = ajaxPositionsDoneAll(positions);

  piecesPositions.objConf = objConf;

  const {
    violationsDescriptionsDesc,
    violationsDescriptionsSpnErrors,
    detailViolationsSetting,
    morePositionsCnt,
    detailPosCntElementValue,
    allPosesInDetailElementChecked,
  } = getReportSettings();

  const skillsManDetails = SkillsManDetailCalculate({
    REPORT: piecesPositions,
    violationsDescriptionsDesc,
    violationsDescriptionsSpnErrors,
    detailViolationsSetting,
    morePositionsCnt,
    detailPosCntElementValue,
    allPosesInDetailElementChecked,
  });

  const copiedSkillsManDetails = JSON.parse(JSON.stringify(skillsManDetails));
  copiedSkillsManDetails.header = { ...skillsManDetails.header };

  return copiedSkillsManDetails;
}

async function violationsDetailsRenderHandler(pageNumber = 0) {
  try {
    const {
      violationsFilter,
      detailViolationRows,
      header,
      accordanceColumns,
      violationsDescriptions,
    } = skillsManDetails.value;
    let { positionBegin = 0, positionEnd = 0 } =
      getPositionsInterval(pageNumber);

    setTitle({
      violationsFilter: violationsFilter,
      detailViolationRows: detailViolationRows,
      header: header,
      positionBegin,
      positionEnd,
    });

    const violationRows = getDetailViolationRows({
      positionBegin,
      positionEnd,
      detailViolationRows,
    });

    const rows = {
      accordanceColumns: accordanceColumns,
      detailViolationRows: violationRows,
      format: formatToDisplay_helper,
      header: header,
      violationsDescriptions: violationsDescriptions,
      violationsFilter: violationsFilter,
    };

    if (!props.isCriticalViolations) {
      rows.detailViolationRows = [rows.detailViolationRows];
      rows.getVolationDesc_helper = getVolationDesc_helper;
      rows.hasDetailsRights = hasDetailsRights_helper(userData.senderRights.templates_names_available_arr);
    }

    isReady.value = true;
    await nextTick();

    renderHtml(rows);
  } catch (ex) {
    title.value = 'Произошла непредвиденная ошибка. Повторите запрос позже';
  }
}

function filterDetailViolationRows() {
  const { detailViolationRows } = skillsManDetails.value;
  if (props.isCriticalViolations) {
    detailViolationRows.map((array) => {
      const arr = array.map((obj) => {
        obj.selected = false;
        return obj;
      });
      return arr;
    });
    return;
  }

  detailViolationRows[0] = detailViolationRows[0]
    .filter((obj, index) => {
      return !!obj.violationsId.length;
    })
    .map((obj) => {
      obj.selected = false;
      return obj;
    });
}

function renderHtml(rows) {
  try {
    document.getElementById('violations-details-modal-section').innerHTML = '';
  } catch (error) {
    if (error.message === `Cannot set property 'innerHTML' of null`) {
      console.error(
        'Произошла ошибка с показом детализации. Отсутствует блок с id = violations-details-modal-section',
      );
    }
    return;
  }
  const detailColumnsString = getDetailColumnsString();

  lodashRenderHtmlByTemplateHtml_helper(
    rows,
    getSkillsManDetailTemplateHtml(detailColumnsString, "skills-man-detail-template-content-modal", 'skillsManDetailsTemplateHtmlExportButton-modal', 'skillsManDetailsTemplateHtmlTitle-modal', rows.hasDetailsRights),
    'violations-details-modal-section',
  );

  document.getElementById(
    'skillsManDetailsTemplateHtmlExportButton-modal',
  ).innerHTML = '';

  const titleNode = document.getElementById(
    'skillsManDetailsTemplateHtmlTitle-modal',
  );

  titleNode.classList.add('d-none');
  titleNode.innerHTML = title.value;
}

function setTitle({
  violationsFilter,
  detailViolationRows,
  header,
  positionBegin,
  positionEnd,
}) {
  const rows = detailViolationRows;

  let timeBegin = 0;
  let timeEnd = 0;

  let startArrIndex = 0;
  let startPosIndex = 0;

  let nextArrIndex = 0;
  let nextPosIndex = 0;

  if (props.isCriticalViolations) {
    const {
      arrIndex: beginArrayIndex,
      posIndex: beginPosIndex,
      time: beginTime,
    } = positionBegin;
    const {
      arrIndex: endArrayIndex,
      posIndex: endPosIndex,
      time: endTime,
    } = positionEnd;

    startArrIndex = beginArrayIndex;
    startPosIndex = beginPosIndex;

    nextArrIndex = endArrayIndex;
    nextPosIndex = endPosIndex;

    timeBegin = beginTime;
    timeEnd = endTime;
  } else {
    startPosIndex = positionBegin;
    nextPosIndex = positionEnd;
  }

  let filterInfo = '';

  if (violationsFilter.isAllId) {
    filterInfo = props.isCriticalViolations
      ? 'выводятся все критические нарушения'
      : 'выводятся все нарушения';
  } else {
    filterInfo = props.isCriticalViolations
      ? props.skill || 'выводятся все критические нарушения'
      : '<span style="color:red">фильтр по нарушениям включен!</span>';
  }

  const conditionSetHeader =
    startArrIndex > -1 &&
    startPosIndex > -1 &&
    nextArrIndex > -1 &&
    nextPosIndex > -1;

  if (!rows[0]?.length || !conditionSetHeader) {
    title.value = `${header.reportName} ${header.reportPeriod} (${filterInfo})`;
    return;
  }

  title.value = `${header.reportName} c ${formatDateHelper(
    new Date(timeBegin),
    'dd.mm.yyyy hh:nn:ss',
  )} по ${formatDateHelper(
    new Date(timeEnd),
    'dd.mm.yyyy hh:nn:ss',
  )} (${filterInfo})`;
}

function closeHandler() {
  emit('close');
  isReady.value = false;
}

function updatePageNumber(num) {
  pageNumber.value = num;
}

watch(
  () => props.show,
  async () => {
    isReady.value = true;
  },
);

onMounted(async () => {
  skillsManDetails.value = await fetchDetails();

  filterDetailViolationRows();
  paginationArray.value = getPagination();

  pagesLength.value = paginationArray.value.length;

  violationsDetailsRenderHandler(-1);
});
</script>

<style lang="scss" scoped>
.preloader {
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 500px) {
  .export-button {
    font-size: 0.8rem;
    & .mobile {
      display: none;
    }
  }
  .title {
    font-size: 0.8rem;
  }
}
</style>
